const get = (key: string): string | null => {
  try {
    return localStorage.getItem(key)
  } catch (e) {
    return null
  }
}

const set = (key: string, value: string): void => {
  try {
    localStorage.setItem(key, value)
  } catch (e) {
    // ignore
  }
}

export const getJSON = <T>(key: string, defaultValue: T | null = null): T => {
  const object = get(key)
  if (object === null) {
    return defaultValue
  }

  return JSON.parse(object) as T
}

export const setJSON = <T>(key: string, value: T): void => {
  set(key, JSON.stringify(value))
}

export const getBoolean = (key: string, defaultValue = false): boolean => {
  const object = get(key)

  if (object === null) {
    return defaultValue
  }

  return object.toLowerCase() === 'true'
}

export const setBoolean = (key: string, value: boolean): void => {
  set(key, value ? 'true' : 'false')
}
