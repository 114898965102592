import React, { FC } from 'react'
import CheckboxIcon from '../../images/icons/checked.svg'

const TermsAndConditionsWelcomeModal: FC = () => {
  return (
    <div className="input-component-wrapper checkbox-component-wrapper">
      <label className="input-wrapper" tabIndex={0}>
        <input type="checkbox" name="terms-checkbox" id="terms-checkbox" />
        <CheckboxIcon />
      </label>
      <label htmlFor="terms-checkbox">
        <p>
          I agree to the{' '}
          <a
            href="https://www.mindrglobal.com/mindr-connect-terms"
            className="link"
            target="_blank"
            rel="noreferrer">
            Terms & Conditions
          </a>{' '}
          and{' '}
          <a
            href="https://www.mindrglobal.com/privacy"
            className="link"
            target="_blank"
            rel="noreferrer">
            Privacy Policy
          </a>
        </p>
      </label>
    </div>
  )
}

export default TermsAndConditionsWelcomeModal
