import { useEffect, RefObject, useRef } from 'react'
import $ from 'jquery'
import axios from 'axios'
import 'canvas-toBlob'
import moment from 'moment-timezone'
import Cookies from 'js-cookie'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import withReactContent from 'sweetalert2-react-content'

const SwalReact = withReactContent(Swal)
// import UploadAvatarWelcomeModal from 'components/UploadAvatarWelcomeModal'
import TermsAndConditionsWelcomeModal from 'components/TermsAndConditionsWelcomeModal'

import { TTimezone } from 'types'

export const defaultSwalParams = {
  confirmButtonText: 'Yes',
  cancelButtonText: 'Cancel',
  showCancelButton: true,
  showCloseButton: window.outerWidth >= 431,
  allowEscapeKey: true,
  allowOutsideClick: true,
  focusConfirm: false,
  customClass: {
    confirmButton: 'button primary',
    cancelButton: 'button cancel',
    actions: 'modal actions',
    popup: 'modal popup',
    header: 'modal header',
    title: 'modal title',
    content: 'modal content',
    closeButton: 'modal close',
  },
  buttonsStyling: false,
  width: 696,
}

export interface IOption {
  label: string
  value: string
}

export interface IUser {
  id: number
  first_name?: string
  full_name: string
  title: string
  email: string
  avatar_data?: any
  avatar_url?: string
  pronouns?: string
}

declare global {
  interface Window {
    authenticity_token: string
    currentUser: {
      id: number
      full_name: string
      first_name: string
      last_name: string
      show_welcome_modal: boolean
    }
    uploadServer: string
    isProxyUpload: string
    timezones: TTimezone[]
  }
}

interface IAdditionalSection extends HTMLElement {
  addRow: () => void
  cleanRows: () => void
}

export const useAdditionalSectionRows = (
  hasNonDeletedRows: boolean,
  componentRef: RefObject<IAdditionalSection>,
  addRow: () => void,
  cleanRows: () => void,
): void => {
  useEffect(() => {
    componentRef.current.addRow = () => {
      addRow()
    }
    componentRef.current.cleanRows = () => {
      cleanRows()
    }
  })

  useEffect(() => {
    if (!hasNonDeletedRows) {
      $(componentRef?.current)
        .closest('.additional-section')
        .find('.additional-section-checkbox')
        .prop('checked', true)
    }
  }, [hasNonDeletedRows])
}

export const useEffectNotOnMount = (
  func: () => void,
  deps: Array<unknown>,
): void => {
  const loaded = useRef(false)
  useEffect(() => {
    if (loaded.current) {
      func()
    } else {
      loaded.current = true
    }
  }, deps)
}

export const asyncForEach = async function (
  array: Array<unknown>,
  callback: (element: unknown, index: number) => void,
): Promise<void> {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index)
  }
}

$(() => {
  $('body').on('keydown', (e) => {
    if (e.key === 'Tab') {
      $('body').removeClass('disable-focus')
    }
  })

  $('body').on('mousedown', () => {
    $('body').addClass('disable-focus')
  })

  Cookies.set('utz', moment.tz.guess())
})

export const asyncFilesUpload = async (
  file: Blob,
  fileName: string,
  fileType: string,
  uploadOptions?: {
    presign: string
    upload: string
  },
): Promise<Record<string, unknown>> => {
  let fileJSON = {}
  if (
    window.uploadServer === 's3' &&
    !window.organization?.proxyUploadEnabled
  ) {
    const {
      data: { fields, url, method, headers },
    } = await axios.get(uploadOptions?.presign || '/dashboard/files/presign', {
      params: { filename: fileName, type: fileType },
    })

    const formData = new FormData()
    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key])
    })

    formData.append('file', file, fileName)
    await axios[method](url, formData, { headers })

    fileJSON = {
      id: fields.key.match(/^cache[^\/]*\/(.+)/)[1], // object key without prefix
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: fileName,
        mime_type: fileType,
      },
    }
  } else {
    const formData = new FormData()
    formData.append('file', file, fileName)
    formData.append('authenticity_token', window.authenticity_token)
    const { data } = await axios.post(
      uploadOptions?.upload || '/dashboard/files/upload',
      formData,
    )

    fileJSON = data
  }
  return fileJSON
}

let loadingCount = 0

export const changeLoadingFormStatusJQuery = (
  form: JQuery<HTMLFormElement>,
  isFinished?: boolean,
): void => {
  const submitButtons = $(form).find('.submit-form-button')
  if (isFinished) {
    --loadingCount
  } else {
    ++loadingCount
  }
  submitButtons.prop('disabled', !!loadingCount)
}

export const changeLoadingFormStatus = (
  form: HTMLFormElement,
  isFinished?: boolean,
): void => {
  const submitButtons = form.querySelectorAll('.submit-form-button')
  if (submitButtons.length === 0) {
    const submitButton = submitButtons[0] as HTMLButtonElement
    if (isFinished) {
      --loadingCount
    } else {
      ++loadingCount
    }
    submitButton.disabled = !!loadingCount
  }
}

export const serverUTCTimeFormat = 'YYYY-MM-DD HH:mm:ss Z'

export const convertUTCToLocalRange = (
  datetimeUTC: string,
  durationSeconds: number,
  justTime?: boolean,
): string => {
  const localTime = moment(
    datetimeUTC.replace('UTC', '+0000'),
    serverUTCTimeFormat,
  )
  const localEndTime = moment(
    datetimeUTC.replace('UTC', '+0000'),
    serverUTCTimeFormat,
  ).add(durationSeconds, 'seconds')
  const zone = moment.tz(moment.tz.guess()).zoneAbbr()

  const format = justTime ? 'h:mma' : 'ddd, MMM Do, YYYY, h:mma'

  return `${localTime.format(format)}-${localEndTime.format('h:mma')} ${zone}`
}

export const escapeHTML = (text: string): string => $('<div>').text(text).html()

export const reportValidity = ($form: JQuery): boolean => {
  let result = true
  $form
    .find('[required]')
    .each((i, requiredField: HTMLInputElement | HTMLTextAreaElement) => {
      const filled = !!requiredField.value.trim()
      const $requiredField = $(requiredField)

      $requiredField.next('.validation-error-message').remove()

      if (!filled) {
        const $error = $('<span>')
          .addClass('validation-error-message')
          .text("Can't be blank")
        $requiredField.after($error)
      }

      result = result && filled
    })
  return result
}

export const copyToClipboard = async ($element: JQuery): Promise<void> => {
  if (!navigator.clipboard) {
    $element.trigger('focus')
    $element.trigger('select')
    // This command is deprecated so should only be used for IE
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand
    document.execCommand('copy')
    return
  }
  await navigator.clipboard.writeText(`${$element.val()}`)
}

const showWelcomeModals = async () => {
  if (window.currentUser && window.currentUser.show_welcome_modal) {
    await SwalReact.fire({
      ...defaultSwalParams,
      width: 502,
      title: 'Terms & Conditions',
      showCancelButton: false,
      confirmButtonText: 'Submit',
      showCloseButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        ...defaultSwalParams.customClass,
        container: 'terms-modal',
      },
      html: <TermsAndConditionsWelcomeModal />,
      onOpen: () => {
        Swal.disableButtons()
        $('#terms-checkbox').on('change', (e) => {
          if ((e.target as HTMLInputElement).checked) {
            Swal.enableButtons()
          } else {
            Swal.disableButtons()
          }
        })
      },
    })

    await axios.patch('/dashboard/profile.json', {
      user: {
        agreed_to_latest_terms: '1',
      },
      authenticity_token: window.authenticity_token,
    })

    // SwalReact.fire({
    //   ...defaultSwalParams,
    //   title: `Welcome ${window.currentUser.first_name}`,
    //   cancelButtonText: 'Close',
    //   width: 380,
    //   showConfirmButton: false,
    //   allowOutsideClick: false,
    //   allowEscapeKey: false,
    //   customClass: {
    //     ...defaultSwalParams.customClass,
    //     container: 'upload-avatar-welcome-modal',
    //   },
    //   html: <UploadAvatarWelcomeModal />,
    // })
  }
}

$(() => {
  $('.convert-to-local').each((_, element) => {
    const { datetimeUtc, duration, justTime } = element.dataset

    $(element).html(
      convertUTCToLocalRange(datetimeUtc, parseInt(duration), !!justTime),
    )
  })

  $('.format-to-local-time').each((_, element) => {
    const $el = $(element)
    const datetimeUTC = $el.text()

    const localTime = moment(
      datetimeUTC.replace('UTC', '+0000'),
      serverUTCTimeFormat,
    )

    if (localTime.isValid()) {
      const format = 'dddd, MMMM Do, YYYY, h:mma'
      $el.text(localTime.format(format))
    }
  })

  $('.convert-date-badge-to-local').each((_, element) => {
    const { datetimeUtc } = element.dataset

    const localTime = moment(
      datetimeUtc.replace('UTC', '+0000'),
      serverUTCTimeFormat,
    )

    $(element).find('.month').html(localTime.format('MMM'))
    $(element).find('.date').html(localTime.format('D'))
    $(element).find('.dow').html(localTime.format('ddd'))
  })

  showWelcomeModals()
})
